import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Tile from "../../components/tile"
import FormLink from "../../components/formLink"

const UsefulContacts = () => (
	<Layout>
		<SEO title="Useful contacts for lupus patients in WA" />
		<h1>Useful contacts</h1>
		<FormLink text="If you have any contact suggestions or edits, " />
		<div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
			<Tile
				text="General Practitioners"
				path="/useful-contacts/general-practitioners"
			/>
			<Tile text="Specialists" path="/useful-contacts/specialists" />
			<Tile
				text="Hospital Immunology/Rheumatology Clinics in WA"
				path="/useful-contacts/wa-hospital-immunology-and-rheumatology-clinics"
			/>
			<Tile
				text="The Lupus WA Community"
				path="/useful-contacts/lupus-wa-community"
			/>
		</div>
	</Layout>
)

export default UsefulContacts
